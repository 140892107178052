/* eslint-disable prefer-spread */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
import {Emitter} from './Emitter';

const evite = (window.evite = window.evite || {});

if (evite.history) {
  throw new Error(
    'Unexpected copy of evite.history module exported, you might have used an unusual import instead of the "evite" alias.'
  );
}

const _history = window.history;

export class History extends Emitter {
  get length() {
    return (_history && _history.length) || 0;
  }

  get state() {
    return (_history && _history.state) || null;
  }

  back() {
    _history?.back?.();
  }

  forward() {
    _history?.forward?.();
  }

  go(index) {
    _history?.go?.(index);
    this.dispatchEvent(new CustomEvent('change'));
  }

  pushState(...args) {
    _history?.pushState?.apply?.(_history, args);
    this.dispatchEvent(new CustomEvent('change'));
  }

  replaceState(...args) {
    _history?.replaceState?.apply?.(_history, args);
    this.dispatchEvent(new CustomEvent('change'));
  }
}

export const history = new History();
